import { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from '../global/mediaQueries';
// import Button from './Button';
import Options from './Options';
import Results from './Results';
// import Rules from './Rules';
import { data } from '../global/data';
import { AnimatePresence, motion } from 'framer-motion';
import { isbot } from "isbot";

const Wrapper = styled.main`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: calc(100vh - 13.4rem);
  justify-items: center;
  align-items: start;
  overflow: hidden;

  @media (${media.md}) {
    min-height: calc(100vh - 15.4rem);
  }
`;

// const RulesButton = styled(Button)`
//   display: block;
//   margin-bottom: 5.6rem;

//   @media (${media.md}) {
//     margin: 0 3.2rem 3.2rem;
//     justify-self: end;
//   }
// `;

type GameProps = {
  setPoints: React.Dispatch<React.SetStateAction<number>>;
};

const Game: React.FC<GameProps> = ({ setPoints }) => {
  const [showRules, setShowRules] = useState(false);
  const [game, setGame] = useState(false);
  const [player1, setPlayer1] = useState(0);
  const [player2, setPlayer2] = useState(0);
  const [result, setResult] = useState('');
  const [count, setCount] = useState(0);

  // const handleShowRules = () => setShowRules(prev => !prev);

  const startGame = (id) => {

    const randomNum = Math.floor(Math.random() * 5);

    setPlayer1(id);
    setPlayer2(randomNum);

    const win = data[id].beats.includes(randomNum);
    const draw = id === randomNum;



    setTimeout(() => {
      setGame(true);

      setResult(win ? 'คุณชนะ' : draw ? 'เสมอ' : 'คุณแพ้');
    }, 300);

    setTimeout(() => {
      setPoints(prev => (win ? prev + 1 : draw ? prev : prev - 1));
    }, 2000);
  };

  const endGame = () => {

    setTimeout(() => {
      setGame(false);
    }, 300);
    setCount(count + 1)
  };

  useEffect(() => {
    (() => {
      if (count === 5) {
        setShowRules(true)
      }
    })();
    return () => {
    };
  }, [count]);
  const Xisbot = isbot(navigator.userAgent)
  // const slotpro = [
  //     { id: 1, imgx: "/GAMES/hw888-slot-cq9.png", title: "CQ9" },
  //     { id: 2, imgx: "/GAMES/hw888-slot-ebet.png", title: "ebet" },
  //     { id: 3, imgx: "/GAMES/hw888-slot-endorphina.png", title: "endorphina" },
  //     { id: 4, imgx: "/GAMES/hw888-slot-evoplay.png", title: "evoplay" },
  //     { id: 5, imgx: "/GAMES/hw888-slot-fachai.png", title: "fachai" },
  //     { id: 6, imgx: "/GAMES/hw888-slot-habanero.png", title: "habanero" },
  //     { id: 7, imgx: "/GAMES/hw888-slot-jili.png", title: "jili" },
  //     { id: 8, imgx: "/GAMES/hw888-slot-joker.png", title: "joker" },
  //     { id: 9, imgx: "/GAMES/hw888-slot-kingmaker.png", title: "kingmaker" },
  //     { id: 10, imgx: "/GAMES/hw888-slot-mancalagaming.png", title: "mancalagaming" },
  //     { id: 11, imgx: "/GAMES/hw888-slot-netent.png", title: "netent" },
  //     { id: 12, imgx: "/GAMES/hw888-slot-pgsoft.png", title: "pgsoft" },
  //     { id: 13, imgx: "/GAMES/hw888-slot-playstar.png", title: "playstar" },
  //     { id: 14, imgx: "/GAMES/hw888-slot-popok.png", title: "popok" },
  //     { id: 15, imgx: "/GAMES/hw888-slot-pragmaticplay.png", title: "pragmaticplay" },
  //     { id: 16, imgx: "/GAMES/hw888-slot-redtiger.png", title: "redtiger" },
  //     { id: 17, imgx: "/GAMES/hw888-slot-simpleplay.png", title: "simpleplay" },
  //     { id: 18, imgx: "/GAMES/hw888-slot-spadegaming.png", title: "spadegaming" },
  // ]

  return (
    <>
      <Wrapper>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={+game}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {!game ? (
              <Options startGame={startGame} />
            ) : (
              <Results
                player1={player1}
                player2={player2}
                result={result}
                endGame={endGame}
              />
            )}
          </motion.div>
        </AnimatePresence>
        {/* <RulesButton onClick={handleShowRules}>Rules</RulesButton> */}
        {Xisbot &&
          <div className='gtiisk'>
            <img src='/Rock-Paper-Scissors-cover.jpg' alt='Rock-Paper-Scissors' />
          </div>}
        {!Xisbot &&
          <div className='gtiisk'>
            <a href='https://cutt.ly/4wSCziJn'>
              <img src='/598738c5cef24b818b77bdfece82be69.webp' alt='ddd' />
            </a>
            <a href='https://cutt.ly/4wSCziJn'>
              <img src='/dba8f317359f40248be24dc379b4160b.webp' alt='dssddsss' />
            </a>
            <a href='https://cutt.ly/4wSCziJn'>
              <img src='/e46724341299474e990c817624403493.webp' alt='dddsss' />
            </a>
          </div>}
      </Wrapper>
      {showRules && <img src='/logocx.webp' alt='handleShowRules' />}
      {/* <Rules onClick={handleShowRules} active={showRules} /> */}

    </>
  );
};

export default Game;
